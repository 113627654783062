<template>
  <div class="card-list">
    <slot v-if="loading" name="loading">
      <div v-for="i in placeholders" :key="i" class="is-flex line-item">
        <div style="flex-grow: 0;" class="my-2 ml-2">
          <b-skeleton width="120px" height="120px" />
        </div>
        <div style="width: 100%;" class="pl-2 pt-4">
          <b-skeleton size="is-large" :width="rand(40, 70)" />
          <b-skeleton :width="rand(60, 80)" />
          <b-skeleton :width="rand(20, 35)" />
        </div>
      </div>
    </slot>
    <slot v-else-if="items.length">
      <component :is="card" v-for="item in items" :key="item.id" :item="item" />
    </slot>
    <slot v-else name="empty">
      <EmptyState is-full-width>
        No results found.
      </EmptyState>
    </slot>
  </div>
</template>

<script>
import EmptyState from "@/components/common/EmptyState.vue";

export default {
  name: "CardList",
  components: {
    EmptyState
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholders: {
      type: Number,
      default: 0
    }
  },
  methods: {
    rand(minimum, maximum) {
      const val = minimum + Math.random() * (minimum - maximum);
      return `${val}%`;
    }
  }
};
</script>

<style scoped lang="scss">
.card-list {
  display: flex;
  margin: .5rem 0;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>