<template>
  <HeaderPage v-if="!$apollo.loading && collection" :title="collection.title" :image="collection.media && collection.media[0] && collection.media[0].url">
    <template #summary>
      <strong v-if="collection.tagline">{{ collection.tagline }}</strong>
      <span v-if="collection.summary">{{ collection.summary }}</span>
    </template>
    <template #body>
      <Section>
        <h3 class="is-size-3">Recent</h3>
        <CardList
          :items="collection.posts"
          :card="ArticleCard"
          :loading="$apollo.loading"
          :placeholders="limit"
        />
      </Section>
    </template>
  </HeaderPage>
  <Page v-else-if="$apollo.loading">
    <Section>
      <b-skeleton size="is-large" width="60%" />
      <div class="my-2">
        <b-skeleton width="20%" />
      </div>
      <div class="my-2">
        <b-skeleton width="100%" height="50vh" />
      </div>
      <div class="my-2">
        <b-skeleton width="100%" />
        <b-skeleton width="100%" />
        <b-skeleton width="85%" />
      </div>
    </Section>
  </Page>
</template>

<script>
import { COLLECTION } from "@/models/resources/operations.gql";
import CardList from "@/components/common/CardList.vue";
import ArticleCard from "@/components/resources/ArticleCard.vue";
import Section from "@/components/common/Section.vue";
import Page from "@/components/common/Page.vue";
import HeaderPage from "@/components/common/HeaderPage.vue";

export default {
  name: "ResourceCollection",
  metaInfo: {
    title: "Articles - "
  },
  components: {
    Page,
    HeaderPage,
    Section,
    CardList
  },
  data() {
    return {
      ArticleCard,
      collection: { posts: [] },
      limit: 6
    };
  },
  apollo: {
    collection: {
      client: "cms",
      query: COLLECTION,
      variables() {
        return {
          skip: 0,
          first: this.limit,
          id: this.$route.params.id
        };
      },
      update({ collection }) {
        return collection;
      }
    }
  }
};
</script>

<style lang="scss">
.hero-body {
  background-size: cover;
}
</style>
