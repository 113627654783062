<template>
  <div v-if="item.authors[0]" class="card elevation-1" :class="{ 'is-featured': featured }">
    <div v-if="featured" class="columns is-two-fifths is-mobile">
      <div v-if="item.media[0]" is-image class="column" @click="onArticleCardClick">
        <img :src="img" class="card-image elevation-2" />
      </div>
      <div class="column is-flex-column">
        <router-link :to="{ name: 'article', params: { slug } }">
          <span class="is-size-1 has-text-weight-semibold">{{ item.title }}</span>
        </router-link>
        <div>
          <span>By</span>
          <router-link
            v-if="item.authors[0].username"
            :to="{
              name: 'user_profile',
              params: { username: item.authors[0].username }
            }"
          >
            {{ item.authors[0].name }}
          </router-link>
          <span v-else>
            {{ item.authors[0].name }}
          </span>
        </div>
        <p v-if="item.tagline" class="my-2" @click="onArticleCardClick">
          <strong>{{ item.tagline }}</strong>
        </p>
        <p class="my-2" @click="onArticleCardClick">{{ item.summary }}</p>
      </div>
    </div>

    <div v-else class="card-contents">
      <div>
        <div class="dt">
          <div>Article</div>
          <div>{{ date }}</div>
        </div>
        <router-link :to="{ name: 'article', params: { slug } }">
          <div is-image class="card-image">
            <div :style="`${item.media[0].url ? `background-image: url(${item.media[0].url})` : ''}`"></div>
          </div>
          <div class="tagline">
            {{ item.title }}
          </div>
        </router-link>
      </div>
      <div v-if="item.authors[0].username" class="bottom">
        <div>By</div>

        <router-link
          :to="{
            name: 'user_profile',
            params: { username: item.authors[0].username }
          }"
        >
          <Pill class="author">
            <Avatar :seed-string="item.authors[0].name" size="small" />
            {{ item.authors[0].name }}
          </Pill>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { toLocalDate } from "@/dates";
import { format, formatDistance } from "date-fns";
import { slugify } from "@/filters.js";
import Pill from "@/components/common/Pill.vue";
import Avatar from "@/components/common/Avatar.vue";

export default {
  name: "ArticleCard",
  components: {
    Pill,
    Avatar
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    featured: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: formatDistance(new Date(this.item.publishedDate), new Date(), {
        addSuffix: true
      }),
      slug: slugify(this.item),
      img: this.item.media[0] ? this.item.media[0].url : "",
      published: format(toLocalDate(this.item.publishedDate), "yyyy-MM-dd")
    };
  },
  methods: {
    onArticleCardClick() {
      this.$router.push({
        name: "article",
        params: {
          slug: this.slug
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../scss/mixins';
.card {
  padding: 15px !important;
  margin: 11px 0;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0.9;
  border: 2px solid $grey300;
  border-radius: 8px;
  @include mobile {
    width: calc(50% - 24px);
  }
  @include portrait {
    width: 100%;
    margin: vwl(11px) 0;
  }
  @include tablet {
    flex-basis: calc(33.333333% - 12px);
    // height: 324px;
  }
  @include desktop {
    flex-basis: calc(25% - 24px);
    margin: 16px 0;
  }

  &.is-featured {
    margin-top: 1rem;
  }

  &:not(.is-featured):hover {
    transform: scale3d(1.1, 1.1, 1.1);
    opacity: 1;
  }

  .card-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .dt {
    display: flex;
    justify-content: space-between;
    div {
      padding: 5px 8px 3px 8px;
      color: #7e7f88;
      line-height: 1;
      @include mobile {
        padding: vwl(5px) vwl(8px) vwl(3px) vwl(8px);
      }
      @include portrait {
        padding: vwp(5px) vwp(8px) vwp(3px) vwp(8px);
      }
    }
    :first-child {
      border-radius: 4px;
      background-color: #f4f5f8;
      font-weight: 500;
      font-size: 14px;
      @include mobile {
        font-size: vwl(12px);
      }
      @include portrait {
        font-size: vwp(12px);
      }
    }
    :last-child {
      font-size: 12px;
      @include mobile {
        font-size: vwl(10px);
      }
      @include portrait {
        font-size: vwp(10px);
      }
    }
  }
  .tagline {
    font-size: 20px;
    font-weight: 400;
    color: #242529;
    @include mobile {
      padding-top: vwl(8px);
      font-size: vwl(16px);
    }
    @include portrait {
      padding-top: vwp(8px);
      font-size: vwp(16px);
    }

    a {
      color: #242529;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    color: #7e7f88;
    font-size: 12px;
    margin-top: 8px;
    div:first-child {
      margin-right: 5px;
    }
    .pill {
      font-size: 12px;
    }
  }
  .card-image {
    position: relative;
    padding: .5em 0;
    div {
      height: 0;
      padding-bottom: 40%;
      width: 100%;
      background-position: 50% 30%;
      background-size: cover;
      border-radius: 8px;
      @include tablet {
        padding-bottom: 66.6666667%;
      }
    }
  }
  &.is-featured {
    flex-basis: auto;
    height: auto;
    .card-image {
      margin: 0;
      height: auto;
      width: 100%;
    }
  }
}
</style>
